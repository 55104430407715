import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import HeaderGeneric from "../components/HeaderGeneric";
import pic04 from "../assets/images/pic04.jpg";

import picChemFight from "../resources/ChemFightLogo60x60.png";
import picAstriarch from "../resources/Astriarch-Logo.png";
import picQlinqo from "../resources/QlinqoLogo60x60.png";

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="Games" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <span className="image main">
              <img src={pic04} alt="" />
            </span>
            <h2>Games</h2>
            <div id="featured-image" class="grid col-460 fit">
              <ul className="icons">
                <li>
                  <a href="/chemfight/">
                    <img src={picChemFight} /> Chem Fight - HTML5 Chemistry
                    battle game
                  </a>
                </li>
                <li>
                  <a href="http://astriarch.com">
                    <img width="60" height="60" src={picAstriarch} /> Astriarch
                    - Ruler of the Stars
                  </a>
                </li>
                <li>
                  <a href="/qlinqo/">
                    <img src={picQlinqo} /> Qlinqo
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Generic;
